<template>
	<Component
		:is="tag"
		class="c-scoped-color-theme"
		:class="{
			[`c-scoped-color-theme--${mappedTheme}`]: mappedTheme,
		}"
	>
		<slot></slot>
	</Component>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';

// Important that they are in hue-order
const themes = ['red', 'blue'];
const themeRemap = {};
const defaultTheme = 'red';

export default {
	name: 'ScopedColorTheme',

	provide() {
		return {
			theme: this.themeObservable,
		};
	},
	inject: {
		injectedTheme: {
			from: 'theme',
			default: () => ({}),
		},
	},

	props: {
		tag: {
			type: String,
			default: 'div',
		},
		theme: {
			type: String,
			default: null,
			validator(value) {
				return [...themes, ...Object.keys(themeRemap)].includes(value);
			},
		},
	},

	data() {
		return {
			themeObservable: Vue.observable({
				color: null,
			}),
		};
	},

	computed: {
		mappedTheme() {
			return (
				themeRemap[this.theme] ||
				this.theme ||
				this.injectedTheme.color ||
				this.$store.getters.theme ||
				defaultTheme
			);
		},

		...mapGetters(['solution']),
	},

	watch: {
		mappedTheme: {
			handler(value) {
				this.$set(this.themeObservable, 'color', value);
			},
			immediate: true,
		},
	},
};
</script>

<style lang="postcss">
:where(.c-scoped-color-theme),
:where(.c-scoped-color-theme--red) {
	--theme-colors-theme: var(--theme-colors-red, --theme-colors-blue);
	--theme-colors-theme-90: var(--theme-colors-red-90, --theme-colors-blue-90);
	--theme-colors-theme-80: var(--theme-colors-red-80, --theme-colors-blue-80);
	--theme-colors-theme-60: var(--theme-colors-red-60, --theme-colors-blue-60);
	--theme-colors-theme-40: var(--theme-colors-red-40, --theme-colors-blue-40);
	--theme-colors-theme-20: var(--theme-colors-red-20, --theme-colors-blue-20);
	--theme-colors-theme-10: var(--theme-colors-red-10, --theme-colors-blue-10);
	--theme-colors-theme-pattern: var(
		--theme-colors-red-pattern,
		--theme-colors-blue-pattern
	);
}
:where(.c-scoped-color-theme--blue) {
	--theme-colors-theme: var(--theme-colors-blue, --theme-colors-red);
	--theme-colors-theme-90: var(--theme-colors-blue-90, --theme-colors-red-90);
	--theme-colors-theme-80: var(--theme-colors-blue-80, --theme-colors-red-80);
	--theme-colors-theme-60: var(--theme-colors-blue-60, --theme-colors-red-60);
	--theme-colors-theme-40: var(--theme-colors-blue-40, --theme-colors-red-40);
	--theme-colors-theme-20: var(--theme-colors-blue-20, --theme-colors-red-20);
	--theme-colors-theme-10: var(--theme-colors-blue-10, --theme-colors-red-10);
	--theme-colors-theme-pattern: var(
		--theme-colors-blue-pattern,
		--theme-colors-red-pattern
	);
}
</style>
