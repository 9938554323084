var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"c-skip-to-content",attrs:{"aria-label":"Spring til indhold"}},[_c('div',{staticClass:"inline-block bg-gray-light text-text filter drop-shadow-sm",staticStyle:{"min-width":"24em"}},[_c('h2',{staticClass:"text-list-title px-layout-margin py-12"},[_vm._v("\n\t\t\tSpring til...\n\t\t")]),_vm._v(" "),_c('ul',{staticClass:"\n\t\t\t\tinline-block\n\t\t\t\tw-full\n\t\t\t\tpt-6\n\t\t\t\tpb-lg/v\n\t\t\t\toverscroll-none\n\t\t\t\toverflow-y-auto\n\t\t\t"},[_vm._l((_vm.skipItems),function(ref,index){
var id = ref.id;
var name = ref.name;
var listeners = ref.listeners;
return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.existingIds.includes(id)),expression:"existingIds.includes(id)"}],key:index,staticClass:"\n\t\t\t\t\t\ttext-body\n\t\t\t\t\t\tpx-layout-margin\n\t\t\t\t\t\thover:bg-gray\n\t\t\t\t\t\tfocus-within:bg-gray\n\t\t\t\t\t"},[_c('a',_vm._g({staticClass:"\n\t\t\t\t\t\t\tinline-block\n\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\tpl-24\n\t\t\t\t\t\t\t>=768:pl-0\n\t\t\t\t\t\t\tpb-12\n\t\t\t\t\t\t\tborder-b border-text border-opacity-50\n\t\t\t\t\t\t\thover:border-opacity-100\n\t\t\t\t\t\t",class:{
							'pt-12': index === 0,
							'pt-20': index > 0,
						},attrs:{"href":("#" + id),"data-focus-ignore":""},domProps:{"textContent":_vm._s(name)}},listeners))])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }