<template>
	<div v-if="active" class="c-developer-tools">
		<!-- Grid columns -->
		<div
			v-if="columnsActive"
			:class="[
				'fixed top-0 left-0 w-visual-screen h-visual-screen',
				'pointer-events-none z-50',
			]"
		>
			<div
				:class="[
					'w-full h-full max-w-layout-max',
					'mx-auto px-layout-margin',
				]"
			>
				<div
					:class="[
						'w-full h-full grid gap-x-layout-gutter',
						`grid-cols-${themeConfig.layout.columns.sm}`,
						`>=768:grid-cols-${themeConfig.layout.columns.md}`,
						`>=1400:grid-cols-${themeConfig.layout.columns.lg}`,
					]"
				>
					<div
						v-for="index in themeConfig.layout.columns.lg"
						:key="`column-${index}`"
						:class="[
							'bg-black bg-opacity-5 h-full',

							{
								'hidden >=768:block':
									index > themeConfig.layout.columns.sm,
								'hidden >=1400:block':
									index > themeConfig.layout.columns.md,
							},
						]"
					></div>
				</div>
			</div>
		</div>

		<!-- Theme configuration -->
		<Portal name="ThemeConfiguration" to="overlay">
			<div
				v-if="themeConfigActive"
				key="themeConfig"
				class="
					c-developer-tools__theme-configuration
					px-layout-margin
					grid grid-cols-12
					text-center
				"
			>
				<!-- Headline row -->
				<div data-header class="sticky left-0 top-0 col-span-3"></div>
				<div
					data-sm
					data-header
					class="sticky top-0 col-span-3 p-md text-h3"
				>
					Smallest breakpoint:<br />
					{{ themeConfig.smViewport }}
				</div>
				<div
					data-md
					data-header
					class="sticky top-0 col-span-3 p-md text-h3"
				>
					Medium breakpoint:<br />
					{{ themeConfig.mdViewport }}
				</div>
				<div
					data-lg
					data-header
					class="sticky top-0 col-span-3 p-md text-h3"
				>
					Largest breakpoint:<br />
					{{ themeConfig.lgViewport }}
				</div>

				<!-- Layout info -->
				<template v-if="themeConfig.layout">
					<div class="col-start-4 col-span-9 p-xs transform">
						The max screen size is set to:
						{{ String(themeConfig.layout.max) }}. The layout should
						stop growing after this point.
					</div>

					<!-- Columns -->
					<div class="col-span-3 p-sm text-h4 text-left">
						Column count:
					</div>
					<div
						data-sm
						class="col-span-3 p-xs flex items-center justify-center"
					>
						{{ themeConfig.layout.columns.sm }}
					</div>
					<div
						data-md
						class="col-span-3 p-xs flex items-center justify-center"
					>
						{{ themeConfig.layout.columns.md }}
					</div>
					<div
						data-lg
						class="col-span-3 p-xs flex items-center justify-center"
					>
						{{ themeConfig.layout.columns.lg }}
					</div>

					<!-- fontSize -->
					<div class="col-span-3 p-sm text-h4 text-left">
						Font Sizes:
					</div>

					<div
						v-for="(font, index) in fontSizes"
						:key="index"
						class="
							col-span-12
							grid grid-cols-12
							border-b-2 border-white
						"
					>
						<div
							data-header
							class="col-span-3 p-sm text-h4 text-left"
						>
							text-{{ font.name }}
						</div>
						<div
							data-sm
							class="
								col-span-3
								p-xs
								flex
								items-center
								justify-center
							"
							:style="`font-size:${font.data.fontSize.sm}px`"
						>
							{{ font.data.fontSize.sm }}
						</div>
						<div
							data-md
							class="
								col-span-3
								p-xs
								flex
								items-center
								justify-center
							"
							:style="`font-size:${font.data.fontSize.md}px`"
						>
							{{ font.data.fontSize.md }}
						</div>
						<div
							data-lg
							class="
								col-span-3
								p-xs
								flex
								items-center
								justify-center
							"
							:style="`font-size:${font.data.fontSize.lg}px`"
						>
							{{ font.data.fontSize.lg }}
						</div>
					</div>

					<!--spacing-->

					<div
						v-for="spacing in spacings"
						:key="spacing.name"
						class="
							col-span-12
							grid grid-cols-12
							border-b-2 border-white
						"
					>
						<div
							data-header
							class="col-span-3 p-sm text-h4 text-left"
						>
							spacing-{{ spacing.name }}
						</div>
						<div
							data-sm
							class="
								col-span-3
								p-xs
								flex
								items-center
								justify-center
							"
							:style="`height:${spacing.data.sm}px`"
						>
							{{ spacing.data.sm }}
						</div>
						<div
							data-md
							class="
								col-span-3
								p-xs
								flex
								items-center
								justify-center
							"
							:style="`height:${spacing.data.md}px`"
						>
							{{ spacing.data.md }}
						</div>
						<div
							data-lg
							class="
								col-span-3
								p-xs
								flex
								items-center
								justify-center
							"
							:style="`height:${spacing.data.lg}px`"
						>
							{{ spacing.data.lg }}
						</div>
					</div>
				</template>
			</div>
		</Portal>
	</div>
</template>

<script>
import { config as themeConfig } from '~/components/shared/ThemeConfiguration';

/**
 * Controls:
 * G = Grid
 * T = Theme Config
 */

export default {
	name: 'DeveloperTools',

	data() {
		return {
			columnsActive: false,
			themeConfigActive: false,
		};
	},

	computed: {
		active() {
			return this.columnsActive || this.themeConfigActive;
		},

		themeConfig() {
			return themeConfig;
		},
		fontSizes() {
			const fontSizes = Object.entries(themeConfig.fontSize).map(
				(entry) => {
					return { name: entry[0], data: entry[1] };
				}
			);
			return fontSizes;
		},
		spacings() {
			const horizontalSpacings = Object.entries(
				themeConfig.horizontalSpacing
			).map((entry) => {
				return { name: entry[0], data: entry[1] };
			});
			const verticalSpacings = Object.entries(
				themeConfig.verticalSpacing
			).map((entry) => {
				return { name: entry[0], data: entry[1] };
			});
			return [...horizontalSpacings, ...verticalSpacings];
		},
	},

	mounted() {
		window.addEventListener('keydown', this.onKeyDown);
	},

	beforeDestroy() {
		window.removeEventListener('keydown', this.onKeyDown);
	},

	methods: {
		onKeyDown({ keyCode }) {
			const { activeElement } = document;
			if (activeElement === document.body || activeElement == null) {
				// G = Grid
				if (keyCode === 71) {
					this.columnsActive = !this.columnsActive;
				}
				// T = Theme Config
				if (keyCode === 84) {
					// this.themeConfigActive = !this.themeConfigActive;
					this.themeConfigActive = !this.themeConfigActive;
				}
			}
		},
	},
};
</script>

<style lang="postcss">
.c-developer-tools {
	z-index: 999999;
}

.c-developer-tools__theme-configuration {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background: white;

	& [data-sm] {
		background: rgba(255, 255, 0, 0.2);

		&[data-header] {
			background: rgba(255, 255, 0, 0.6);
		}
	}
	& [data-md] {
		background: rgba(128, 255, 0, 0.2);

		&[data-header] {
			background: rgba(128, 255, 0, 0.6);
		}
	}
	& [data-lg] {
		background: rgba(0, 255, 0, 0.2);

		&[data-header] {
			background: rgba(0, 255, 0, 0.6);
		}
	}
}
</style>
