<script>
export default {
	name: 'SkipToContent',

	props: {
		content: {
			type: [Object, Array],
			default: () => ({
				main: 'Main content',
			}),
		},
	},

	data() {
		return {
			existingIds: [],
		};
	},

	computed: {
		// Streamline the content into an object of id/object pairings
		computedContent() {
			const computedContent = {};
			if (Array.isArray(this.content)) {
				// Add id strings from array
				this.content.forEach((id) => {
					if (id) {
						computedContent[id] = { name: id, action: null };
					}
				});
			} else if (typeof this.content === 'object') {
				// Add id/name pairing strings or id/object pairings from object
				Object.keys(this.content).forEach((id) => {
					const value = this.content[id];
					const { name = value, action } =
						typeof value === 'object' ? value : {};
					if (id) {
						computedContent[id] = { name: name || id, action };
					}
				});
			} else if (this.content) {
				// Add string
				const name = String(this.content);
				computedContent[name] = { name, action: null };
			}
			return computedContent;
		},

		// Form a list of objects
		skipItems() {
			return Object.keys(this.computedContent)
				.filter(Boolean)
				.map((id) => {
					const value = this.computedContent[id];
					return {
						id,
						name: value.name,
						listeners: value.action
							? {
									click: (e) => {
										value.action(e);
									},
							  }
							: {
									click: () => {
										document.getElementById(id)?.focus?.();
									},
							  },
					};
				});
		},
	},

	beforeMount() {
		const mutationObserver = new MutationObserver((mutations) => {
			this.updateExistingIds();
		});
		mutationObserver.observe(document.documentElement, {
			attributes: true,
			childList: true,
			subtree: true,
		});
	},

	methods: {
		updateExistingIds() {
			this.$set(this, 'existingIds', []);
			Object.keys(this.computedContent).forEach((id) => {
				if (document.getElementById(id)) {
					this.$set(this.existingIds, this.existingIds.length, id);
				}
			});
		},
	},
};
</script>
