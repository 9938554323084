<template>
	<ScopedColorTheme class="l-main" :theme="theme">
		<SkipToContent
			:content="{
				main: 'Hovedindhold',
				'table-of-contents': 'Indholdsfortegnelse',
				footer: 'Sidefod',
			}"
		/>

		<!--
			Remove if cookieinformation is not picked as
			the cookie banner solution.
		-->
		<CookieInformation :tag="false" />

		<!--
			Go to ~/assets/js/theme-configuration.default.js to set
			the default solution configuration (typically
			the configuration of the main solution).
		-->
		<ThemeConfiguration />

		<OverlayPortalTarget />
		<Transition name="t-main__header">
			<SiteHeader
				v-if="!isSearchPage && !isLoginPage"
				:invert="
					['employee', 'employeeEn'].includes($store.state.template)
				"
			/>
		</Transition>

		<!-- page-top is used by toc to scroll to top -->
		<span id="page-top"></span>
		<main id="main">
			<Nuxt />
			<div class="t-page-main__cover">
				<img
					src="~/assets/svgs/transition-covers/transition-cover-0.svg"
					class="w-full h-full object-cover opacity-40"
					alt=""
				/>
			</div>
		</main>

		<Transition name="t-main__footer">
			<SiteFooter
				v-if="!isSearchPage && !isLoginPage"
				class="l-main__footer"
			/>
		</Transition>

		<DeveloperTools v-if="false" />
	</ScopedColorTheme>
</template>

<script>
import { mapGetters } from 'vuex';

import CookieInformation, {
	cookieInformationData,
} from '~/components/shared/CookieInformation';
import ScopedColorTheme from '~/components/main/ScopedColorTheme';
import SkipToContent from '~/components/main/SkipToContent';
import ThemeConfiguration from '~/components/shared/ThemeConfiguration';
import OverlayPortalTarget from '~/components/main/OverlayPortalTarget';
import SiteHeader from '~/components/main/SiteHeader';
import SiteFooter from '~/components/main/SiteFooter';
import DeveloperTools from '~/components/shared/DeveloperTools';

export default {
	name: 'MainDefault',

	components: {
		ScopedColorTheme,
		SkipToContent,
		CookieInformation,
		ThemeConfiguration,
		OverlayPortalTarget,
		SiteHeader,
		SiteFooter,
		DeveloperTools,
	},

	data() {
		return {
			matomoIsReady: false,
			matomoPollTimeout: null,
		};
	},

	computed: {
		...mapGetters(['theme']),

		searchPageUrl() {
			return this.$store.state.site?.searchPage?.url;
		},
		isSearchPage() {
			return this.$route.path === this.searchPageUrl;
		},
		isLoginPage() {
			return this.$store.state.template === 'loginPage';
		},

		cookieInformationData() {
			return cookieInformationData;
		},
		matomoStatisticsEnabled() {
			return (
				this.matomoIsReady &&
				!!this.cookieInformationData?.categories?.statistic
			);
		},
	},

	watch: {
		// Set at unset Matomo
		matomoStatisticsEnabled: {
			immediate: true,
			handler(statistic) {
				if (typeof window === 'undefined') {
					return;
				}
				if (!this.matomoIsReady) {
					this.pollMatomoReady();
					return;
				}
				if (statistic) {
					this.$matomo.setCookieConsentGiven();
					this.$matomo.rememberCookieConsentGiven();
				} else {
					this.$matomo.forgetCookieConsentGiven();
				}
			},
		},
	},

	methods: {
		pollMatomoReady() {
			window.clearTimeout(this.matomoPollTimeout);
			if (this.$matomo) {
				this.matomoIsReady = true;
				return;
			}
			this.matomoPollTimeout = window.setTimeout(
				this.pollMatomoReady,
				100
			);
		},
	},
};
</script>

<style lang="postcss">
.l-main {
	@apply w-full max-w-layout-max mx-auto;
	/*
		Force scrollbars to be visible.
	*/
	min-height: max(100%, var(--visual-viewport-height, 100vh) * 1.001);

	/*
		Else the backgrop blur of the burger
		menu doesn't work properly.
	*/
	&:before {
		@apply fixed top-0 left-0 w-full h-full;
		content: '';
		background-color: white;
		z-index: -1;
	}
}

/*
	We make sure all pages has a minimum height as
	to avoid the footer to be placed at the top of
	the page.
*/
:where(.l-main #main),
:where(.l-main #main > *) {
	@apply w-full h-full;
	min-height: calc(var(--visual-viewport-height, 100vh) - 32px);
}

/*
	We set a base bottom padding on all sites, but
	which is easily overwriteable by the individual
	doctypes.
*/
:where(.l-main #main > *:not([class*='--no-footer-gap'])) {
	@apply pb-3xl/v;
}

.t-main__header-enter-active,
.t-main__header-leave-active {
	transition: translate 300ms 300ms, opacity 300ms 300ms;
}
.t-main__header-enter,
.t-main__header-leave-to {
	opacity: 0;
	translate: 0 -10%;
}

.t-main__footer-enter-active,
.t-main__footer-leave-active {
	transition: translate 300ms 300ms, opacity 300ms 300ms;
}
.t-main__footer-enter,
.t-main__footer-leave-to {
	opacity: 0;
	translate: 0 20%;
}
</style>
