<template>
	<Portal name="burgerMenu" to="overlay">
		<TransitionExt name="burger-menu" duration="400">
			<ScopedColorTheme
				v-if="active"
				id="c-burger-menu"
				ref="menu"
				v-trap-focus.loop="{
					active,
					additionalElementsBefore: '#search-toggle, #menu-toggle',
				}"
				tag="nav"
				aria-label="Menu"
				class="c-burger-menu"
				:class="{
					'c-burger-menu--sub-active': currentSubMenu,
				}"
				theme="red"
				@click.native.capture.self="requestClose"
			>
				<!-- Left hand side -->
				<div
					class="
						c-burger-menu__left
						relative
						flex flex-col
						items-end
						w-full
						>=1024:w-1/2
						h-full
						px-layout-margin
						>=1024:pr-lg/h
						pb-lg/v
						bg-theme
						text-right
						>=1024:z-10
						overflow-y-auto
					"
					@focusin.capture="onFocusinLeft"
				>
					<ul
						v-if="primary.length"
						id="c-burger-menu__main"
						:class="[
							'flex-grow flex-shrink-0 flex flex-col items-end gap-2xs/v',
							'w-4/4col >=1024:w-6/12col >=1024:pl-16 >=1441:pl-0',
						]"
					>
						<li
							v-for="(item, index) in primary"
							:key="index"
							ref="mainItem"
						>
							<Component
								:is="
									item.hasDestination
										? 'NuxtLinkExt'
										: 'button'
								"
								:id="`c-burger-menu__main-item-${index}`"
								:to="item.url || null"
								class="
									c-burger-menu__main-item
									relative
									text-burger
									font-bold
									uppercase
									transition-opacity
									duration-200
								"
								:class="{
									'opacity-60 hover:opacity-100 focus-visible:opacity-100':
										currentSubMenu &&
										currentSubMenu !== item,
								}"
								:aria-controls="
									item.hasDestination
										? null
										: 'c-burger-menu__sub'
								"
								:aria-owns="
									item.items
										? item.items
												.map(
													(_, subIndex) =>
														`c-burger-menu__sub-item-${subIndex}`
												)
												.join(' ')
										: null
								"
								:aria-expanded="
									item.hasDestination
										? null
										: currentSubMenu === item
										? 'true'
										: 'false'
								"
								:tabindex="
									currentSubMenuIndex >= 0 &&
									index > currentSubMenuIndex
										? '2'
										: '1'
								"
								@click="() => onPrimaryClick(item)"
							>
								<SvgIconGt
									v-if="item.url"
									class="
										absolute
										right-0
										top-1/2
										transform
										-translate-y-1/2
										w-xs/h
									"
								/>
								<span>
									<span
										class="
											<375:text-h2
											inline-block
											break-all
										"
										v-text="item.name"
									></span>
								</span>
							</Component>
						</li>
					</ul>

					<ul
						v-if="secondary.length && lang !== 'en'"
						ref="secondary"
						class="
							mt-md/v
							flex-grow-0 flex-shrink-0 flex
							justify-end
							flex-wrap
							gap-x-40 gap-y-4xs/v
							w-4/4col
							>=1024:w-6/12col
							max-w-full
							overflow-hidden
						"
					>
						<li
							v-for="item in secondary"
							:key="item.id"
							class="c-burger-menu__secondary-item"
						>
							<NuxtLinkExt
								:to="item.url"
								class="text-burger-secondary uppercase"
								tabindex="2"
								v-text="item.name"
							/>
						</li>
					</ul>
				</div>

				<!-- Right hand side -->
				<Transition name="t-burger-menu__right" mode="out-in" appear>
					<div
						v-if="currentSubMenu"
						class="
							c-burger-menu__right
							absolute
							>=1024:left-1/2
							flex flex-col
							items-start
							w-full
							>=1024:w-1/2
							h-full
							px-layout-margin
							>=1024:pl-lg/h
							pb-lg/v
							bg-theme
							>=1024:bg-theme-80
							text-right
							>=1024:text-left
							z-20
							>=1024:z-0
							overflow-y-auto
						"
					>
						<header
							class="
								fixed
								>=1024:hidden
								w-58
								h-site-header
								flex
								items-center
							"
						>
							<button
								class="
									w-58
									h-58
									transform
									-scale-100
									flex
									items-center
									justify-center
									rounded-full
								"
								tabindex="1"
								@click="onBackClick"
							>
								<SvgIconArrow class="w-20 h-18" />
							</button>
						</header>
						<Transition name="t-burger-menu__sub" mode="out-in">
							<ul
								v-if="
									currentSubMenu.items &&
									currentSubMenu.items.length
								"
								id="c-burger-menu__sub"
								:key="currentSubMenuIndex"
								class="
									w-full
									>=1024:w-6/12col
									flex-grow flex flex-col
									gap-y-3xs/v
									>=1024:gap-y-30
								"
							>
								<li
									v-for="(
										item, index
									) in currentSubMenu.items"
									:key="item.id"
								>
									<NuxtLinkExt
										:id="`c-burger-menu__sub-item-${index}`"
										:to="item.url"
										tabindex="1"
										class="
											c-burger-menu__sub-item
											text-burger-sub
											font-light
											uppercase
											>=1024:normal-case
										"
										:class="
											isPathSub(item)
												? '>=1024:font-semibold underline'
												: ''
										"
										v-text="item.name"
									/>
								</li>
							</ul>
						</Transition>

						<!-- Secondary menu on mobile -->
						<ul
							v-if="secondary.length"
							class="
								>=1024:hidden
								mt-md/v
								flex-grow-0 flex-shrink-0 flex
								justify-end
								flex-wrap
								gap-x-40 gap-y-4xs/v
								w-4/4col
								>=1024:w-6/12col
								max-w-full
								overflow-hidden
							"
						>
							<li
								v-for="item in secondary"
								:key="item.id"
								class="c-burger-menu__secondary-item"
							>
								<NuxtLinkExt
									:to="item.url"
									class="text-burger-secondary uppercase"
									tabindex="3"
									v-text="item.name"
								/>
							</li>
						</ul>
					</div>
				</Transition>
			</ScopedColorTheme>
		</TransitionExt>
	</Portal>
</template>

<script>
import ScopedColorTheme from '~/components/main/ScopedColorTheme';
import trapFocus from '~/directives/shared/trap-focus.directive.js';
import SvgIconArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgIconGt from '~/assets/svgs/icon-gt.svg?inline';

export default {
	name: 'BurgerMenu',

	components: {
		ScopedColorTheme,
		SvgIconArrow,
		SvgIconGt,
	},

	directives: {
		trapFocus,
	},

	props: {
		active: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		const { navigation = {} } = this.$store.state?.site;
		const lang =
			{
				11050: 'en',
			}[this.$store.state?.site?.cultureId] || 'da';

		return {
			lang,
			primary: navigation.primary || [],
			secondary: navigation.secondary || [],
			currentSubMenu: null,

			secondaryItemsHeight: navigation.secondary.length ? 60 : 0,
		};
	},

	computed: {
		currentSubMenuIndex() {
			return this.primary.indexOf(this.currentSubMenu);
		},
	},

	watch: {
		active(value) {
			if (value) {
				window.addEventListener('keyup', this.onKeyup);
				window.addEventListener('resize', this.setSecondaryItemsHeight);
				this.currentSubMenu = this.getCurrentPageMainItem();
				this.setSecondaryItemsHeight();
			} else {
				window.removeEventListener('keyup', this.onKeyup);
				window.removeEventListener(
					'resize',
					this.setSecondaryItemsHeight
				);
				this.currentSubMenu = null;
			}
		},
		'$route.path'() {
			this.requestClose();
		},
	},

	beforeDestroy() {
		window.removeEventListener('keyup', this.onKeyup);
		window.removeEventListener('resize', this.setSecondaryItemsHeight);
	},

	methods: {
		onPrimaryClick(item) {
			if (item === this.currentSubMenu || item.hasDestination) {
				this.currentSubMenu = null;
				item.hasDestination && this.requestClose();
			} else {
				this.currentSubMenu = item;

				// Just in case the user tabbing through content
				if (window.innerWidth < 1024) {
					document.activeElement?.blur?.();
					this.$nextTick(() => {
						this.$el
							.querySelector('.c-burger-menu__sub-item')
							?.focus?.();
					});
				}
			}
		},

		onKeyup(e) {
			if (this.active) {
				if (e.key === 'Escape') {
					const subMenu =
						document.getElementById('c-burger-menu__sub');
					if (
						document.activeElement &&
						subMenu?.contains?.(document.activeElement)
					) {
						// Close the sub menu
						this.$refs.mainItem?.[
							this.currentSubMenuIndex
						]?.firstChild?.focus?.();
						this.currentSubMenu = null;
					} else {
						// Close the whole menu
						this.requestClose();

						e.preventDefault();
						if (
							document.activeElement &&
							this.$refs.menu?.$el?.contains?.(
								document.activeElement
							)
						) {
							window.setTimeout(() => {
								document
									.getElementById('menu-toggle')
									?.focus?.();
							}, 100);
						}
					}
				}
			}
		},

		onFocusinLeft() {
			// Just in case the user tabbing through content
			if (window.innerWidth < 1024) {
				this.currentSubMenu = null;
			}
		},

		onBackClick() {
			this.currentSubMenu = null;
		},

		getCurrentPageMainItem() {
			return this.primary.find((item) => {
				return (
					item?.items?.find?.((subItem) =>
						this.isPathSub(subItem, false)
					) ||
					item?.items?.find?.((subItem) =>
						this.isPathSub(subItem, true)
					)
				);
			});
		},
		isPathSub(item, includeChildren = false) {
			let { pathname: itemPath } = new URL(
				item.url,
				'https://example.com'
			);
			let { path } = this.$route;
			itemPath = itemPath.split('/').filter(Boolean).join('/');
			path = path.split('/').filter(Boolean).join('/');

			if (includeChildren) {
				return path.indexOf(itemPath) === 0;
			}

			return itemPath === path;
		},

		setSecondaryItemsHeight() {
			if (
				this.secondary.length &&
				this.active &&
				window.innerWidth < 1024 &&
				this.$refs.secondary
			) {
				const { height } = this.$refs.secondary.getBoundingClientRect();
				this.secondaryItemsHeight = height + 40;
			}
		},

		requestClose() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="postcss">
.c-burger-menu {
	@apply absolute left-0 top-0 w-full h-full flex bg-black text-white bg-opacity-40;
	z-index: 500;
	backdrop-filter: blur(4px);
}

.c-burger-menu:after {
	content: '';
	@apply absolute block left-0 top-0 w-full h-full z-10 bg-transparent transition-colors duration-300 pointer-events-none;
}
.c-burger-menu--sub-active:after {
	@apply bg-black bg-opacity-20;
}

.c-burger-menu__left {
	@apply text-burger;
	padding-top: calc(130px + 31px - 0.9em);
}
.c-burger-menu__right > ul:first-of-type {
	@apply text-burger-sub;
	padding-top: calc(130px + 31px - 1em);
}

.c-burger-menu__main-item,
.c-burger-menu__main-item:hover,
.c-burger-menu__main-item:focus-visible {
	text-underline-offset: 0.2em;
	text-decoration-thickness: 2px !important;

	& svg + span {
		padding-right: calc(
			15px +
				var(
					--theme-horizontalSpacing-xs-h,
					var(--theme-horizontalSpacing-xs-h--sm)
				)
		);
	}
}
.c-burger-menu__sub-item {
	text-underline-offset: 0.4em;
	text-decoration-thickness: 1px !important;
}

.c-burger-menu__secondary-item {
	@apply relative text-body;

	&:after {
		@apply absolute opacity-40;
		content: '/' / '';
		left: calc(100% + 20px);
		transform: translateX(-50%);
	}
}

@screen >=1024 {
	.c-burger-menu--sub-active:after {
		@apply bg-transparent;
	}

	.c-burger-menu__main-item,
	.c-burger-menu__main-item:hover,
	.c-burger-menu__main-item:focus-visible {
		text-decoration-thickness: 3px !important;

		& svg {
			opacity: 0;
			transition: opacity 200ms ease-out;
		}

		& svg + span {
			display: inline-block;
			padding-right: calc(
				20px +
					var(
						--theme-horizontalSpacing-xs-h,
						var(--theme-horizontalSpacing-xs-h--sm)
					)
			);
			transform: translateX(
				calc(
					20px +
						var(
							--theme-horizontalSpacing-xs-h,
							var(--theme-horizontalSpacing-xs-h--sm)
						)
				)
			);
			transition: transform 200ms ease-out;
		}
	}
	.c-burger-menu__main-item:hover,
	.c-burger-menu__main-item:focus-visible {
		& svg {
			opacity: 1;
		}

		& svg + span {
			transform: translateX(0);
		}
	}
	.c-burger-menu__sub-item {
		text-decoration-thickness: 2px !important;
	}
}

/* Transitions */
.t-burger-menu-enter-active,
.t-burger-menu-leave-active {
	transition: opacity ease-out;

	& .c-burger-menu__left,
	& .c-burger-menu__right {
		transition: transform;
		transition-duration: inherit;
	}
}
.t-burger-menu-leave-active {
	transition: opacity ease-in;
}
.t-burger-menu-enter,
.t-burger-menu-leave-to {
	opacity: 0;

	& .c-burger-menu__left {
		transform: translateX(40px);
	}
	& .c-burger-menu__right {
		transform: translateX(50%);
	}
}

.t-burger-menu__right-enter-active,
.t-burger-menu__right-leave-active {
	transition: opacity 400ms, transform 400ms;
}
.t-burger-menu__right-enter,
.t-burger-menu__right-leave-to {
	opacity: 0.25;
	transform: translateX(100%);
}

.t-burger-menu__sub-enter-active,
.t-burger-menu__sub-leave-active {
	transition: opacity 400ms, transform 400ms;
}
.t-burger-menu__sub-enter,
.t-burger-menu__sub-leave-to {
	opacity: 0;
	transform: translateX(40px);
}

@screen >=1024 {
	.t-burger-menu-enter,
	.t-burger-menu-leave-to {
		& .c-burger-menu__left {
			transform: translateX(-40px);
		}
		& .c-burger-menu__right {
			transform: translateX(-50%);
		}
	}
	.t-burger-menu__right-enter,
	.t-burger-menu__right-leave-to {
		transform: translateX(-100%);
	}
	.t-burger-menu__sub-enter,
	.t-burger-menu__sub-leave-to {
		transform: translateX(-40px);
	}
}
</style>
