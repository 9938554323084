<template>
	<nav class="c-skip-to-content" aria-label="Spring til indhold">
		<div
			class="inline-block bg-gray-light text-text filter drop-shadow-sm"
			style="min-width: 24em"
		>
			<h2 class="text-list-title px-layout-margin py-12">
				Spring til...
			</h2>
			<ul
				class="
					inline-block
					w-full
					pt-6
					pb-lg/v
					overscroll-none
					overflow-y-auto
				"
			>
				<template v-for="({ id, name, listeners }, index) in skipItems">
					<li
						v-show="existingIds.includes(id)"
						:key="index"
						class="
							text-body
							px-layout-margin
							hover:bg-gray
							focus-within:bg-gray
						"
					>
						<a
							class="
								inline-block
								w-full
								pl-24
								>=768:pl-0
								pb-12
								border-b border-text border-opacity-50
								hover:border-opacity-100
							"
							:class="{
								'pt-12': index === 0,
								'pt-20': index > 0,
							}"
							:href="`#${id}`"
							data-focus-ignore
							v-on="listeners"
							v-text="name"
						></a>
					</li>
				</template>
			</ul>
		</div>
	</nav>
</template>

<script>
import SkipToContent from '~/components/shared/SkipToContent';

export default {
	name: 'SkipToContent',
	extends: SkipToContent,
};
</script>

<style lang="postcss">
:where(.c-skip-to-content) {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1200;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 0;
	overflow: hidden;
}

:where(.c-skip-to-content:focus-within) {
	height: auto;
	overflow: visible;
}
</style>
