<template>
	<div class="c-overlay-portal-target">
		<PortalTarget
			:id="`c-overlay-portal-target-${_uid}`"
			ref="portalTarget"
			multiple
			:name="name"
			:class="[
				'c-overlay-portal-target__target z-50',
				{ 'c-overlay-portal-target__target--active': hasContent },
			]"
			@change="onChange"
		/>
	</div>
</template>

<script>
export default {
	name: 'OverlayPortalTarget',

	props: {
		name: {
			type: String,
			default: 'overlay',
		},
	},

	data() {
		return {
			hasContent: false,
			scrollTop: 0,
		};
	},

	beforeMount() {
		document.body.addEventListener('scroll', this.captureScroll, {
			passive: false,
			capture: true,
		});
	},

	beforeDestroy() {
		document.body.removeEventListener('scroll', this.captureScroll, false);
	},

	methods: {
		onChange() {
			this.$nextTick(() => {
				/**
				 * Check whether or not the overlay has
				 * any content, taking transitions, ie.
				 * the -leave-active class into consideration,
				 * and using this to block scroll while
				 * the overlay is active.
				 */
				const child = document.querySelector(
					`#c-overlay-portal-target-${this._uid} > *`
				);

				const classes = child?.classList || [];
				const leaving = Array.from(classes).reduce((acc, cur) => {
					return acc || cur.indexOf('-leave-active') !== -1;
				}, false);

				/*
					This part has been extended to set the
					overflow of the html-tag to scroll to
					keep the scrollbars around when the
					overlay is showing.
					To make this work, we need to move the
					scroll position between the html-tag and
					the body-tag.
				*/

				const scrollTop = Math.max(
					document.documentElement.scrollTop,
					document.body.scrollTop
				);
				this.hasContent = !!(child && !leaving);
				document.documentElement.classList[
					this.hasContent ? 'add' : 'remove'
				]('js-overlay-active');

				if (this.hasContent) {
					document.body.scrollTop = scrollTop;
					this.scrollTop = scrollTop;
				} else {
					document.documentElement.scrollTop = scrollTop;
				}

				this.$emit('change', this.hasContent);
			});
		},
		captureScroll(e) {
			if (this.hasContent) {
				e.preventDefault();
				e.stopPropagation();
				document.body.scrollTop = this.scrollTop;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-overlay-portal-target__target {
	@apply fixed top-0 left-0 w-visual-screen h-visual-screen;
	@apply pointer-events-none;
}

.c-overlay-portal-target__target--active > * {
	@apply pointer-events-auto overscroll-none;
}

html.js-overlay-active {
	@apply overflow-y-scroll !important;
	& body {
		@apply fixed overflow-hidden !important;
	}
}
</style>
